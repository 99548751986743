import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MyGameLoginGuard } from './my-game-login-guard';

@Component({
    selector: 'pk-my-game-login',
    template: '',
    imports: [CommonModule, RouterModule],
    providers: [MyGameLoginGuard],
    standalone: true,
})
export class MyGameLoginComponent {
    constructor() {}
}
