import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { NativeAppService, STORE_PREFIX } from '@frontend/vanilla/core';
import { PokerFrameworkModule } from '@pokercore/module';
import { HomepageModule } from '@pokercore/module/homepage';
import * as Hammer from 'hammerjs';

import { ApiModule } from './api/api.module';
import { appRoutes } from './app.routes';
import { PokerBootstrapModule } from './poker-bootstrap/poker-bootstrap.module';

export class HammerConfig extends HammerGestureConfig {
    override overrides = <any>{
        swipe: { enable: true, direction: Hammer.DIRECTION_ALL },
    };
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        HammerModule,
        HomepageModule.forRoot(),
        ApiModule.forRoot(),
        LayoutModule,
        PokerFrameworkModule.forRoot(),
        MatDialogModule,
        PokerBootstrapModule.forRoot(),
    ],
    providers: [{ provide: STORE_PREFIX, useValue: 'poker.' }, { provide: MatDialogRef, useValue: {} }, NativeAppService],
    declarations: [],
})
export class AppModule {}
