import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';

import { ContentService, LoadingIndicatorService, Page, PageMatrixService } from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { NavigationBarComponent, NavigationBarPokerComponent, PokerHomePageClientConfig, ScrollToTopComponent } from '@pokercore/module/core';
import { Subject, Subscription } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'poker-application-launcher',
    templateUrl: 'poker-application-launcher.component.html',
    imports: [CommonModule, RouterModule, NavigationBarComponent, NavigationBarPokerComponent, ScrollToTopComponent, PageMatrixComponent, DslPipe],
    standalone: true,
})
export class PokerApplicationLauncherComponent implements OnInit {
    private unsubscribe = new Subject<void>();
    content: any;
    pageMatrixServiceSub: Subscription;
    isPagematrixReady: boolean;
    launchProtocol: any;
    product: string;
    launchURL: string;
    action: string;
    server: string;
    port: string;
    category: string;
    ugUrl: string;
    suUrl: string;
    extendedAttr: string;
    isExecuted: boolean = true;
    showConfigureNavigation: boolean = false;
    disableHtmlHeadTag: boolean = false;

    constructor(
        private contentService: ContentService,
        private activatedRoute: ActivatedRoute,
        private loadingIndicatorService: LoadingIndicatorService,
        private pageMatrixService: PageMatrixService,
        private vnPageService: Page,
        private pokerHomePageClientConfig: PokerHomePageClientConfig,
    ) {}

    ngOnInit() {
        this.showConfigureNavigation = this.pokerHomePageClientConfig.useBwinNavigation;
        this.disableHtmlHeadTag = this.pokerHomePageClientConfig.disableHtmlHeadTags;

        this.activatedRoute.url.pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
            const loadingIndicator = this.loadingIndicatorService.start();

            this.contentService
                .getJson(this.activatedRoute.snapshot.data.publicPageRoot + url.join('/'), { filterOnClient: true })
                .pipe(catchError(() => this.contentService.getJson('App-v1.0/partials/notfound', { filterOnClient: true })))
                .subscribe((data) => {
                    this.content = data;
                    loadingIndicator.done();
                });
        });

        this.pageMatrixServiceSub = this.pageMatrixService.whenReady.subscribe(() => {
            this.isPagematrixReady = true;
        });

        setTimeout(() => {
            this.applicationLauncher();
        }, 4000);
    }

    applicationLauncher() {
        this.action = this.activatedRoute.snapshot.queryParams['A'];
        this.server = this.activatedRoute.snapshot.queryParams['S'];
        this.port = this.activatedRoute.snapshot.queryParams['PO'];
        this.category = this.activatedRoute.snapshot.queryParams['C'];
        this.ugUrl = this.activatedRoute.snapshot.queryParams['UG'];
        this.suUrl = this.activatedRoute.snapshot.queryParams['SU'];

        this.product = this.vnPageService.domain.substring(1, this.vnPageService.domain.lastIndexOf('.'));
        this.launchProtocol = 'launcher-' + this.product.toLowerCase();
        this.launchURL = this.launchProtocol + '://-P=' + this.product;
        if (this.server) {
            this.launchURL += ' -S=' + this.server;
        }
        if (this.port) {
            this.launchURL += '#' + this.port;
        }
        if (this.action) {
            this.launchURL += ' -action=' + this.action;
        }
        if (this.category) {
            this.launchURL += ' -category=' + this.category;
        }
        if (this.ugUrl) {
            this.launchURL += ' -UG=' + this.ugUrl;
        }
        if (this.extendedAttr) {
            this.launchURL += ' -extendedAttr=' + this.extendedAttr;
        }
        if (this.suUrl) {
            this.launchURL += ' -SU=' + this.suUrl;
        }
        window.location.href = this.launchURL;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
