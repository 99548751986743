import { Routes } from '@angular/router';

import { VANILLA_LAZY_ROUTES } from '@frontend/loaders/routes';
import { HomepageComponent, HomepageResolver } from '@pokercore/module/homepage';
import { LauncherActivateGuard, PokerPublicPageLoaderComponent } from '@pokercore/module/poker-public-page';
import { PlayGuard } from '@pokercore/module/poker-web-client';

import { ClientRTMSComponent } from './client-rtms/client-rtms.component';
import { MyGameLoginGuard } from './my-game-login/my-game-login-guard';
import { MyGameLoginComponent } from './my-game-login/my-game-login.component';
import { PokerApplicationLauncherComponent } from './poker-application-launcher/poker-application-launcher.component';
import { PokerClientDownloadComponent } from './poker-client-download/poker-client-download.component';
import { PokerDownloadDeactivateGuard } from './poker-client-download/poker-download-deactivate-guard';
import { POKER_LAZY_ROUTES } from './poker-lazy.routes';

export const appRoutes: Routes = [
    {
        path: '',
        component: HomepageComponent,
        resolve: {
            initData: HomepageResolver,
        },
    },
    {
        path: '{culture}',
        children: [
            ...VANILLA_LAZY_ROUTES,
            {
                path: '',
                component: HomepageComponent,
                resolve: {
                    initData: HomepageResolver,
                },
            },
            {
                path: 'pokerclub',
                children: [
                    {
                        path: 'create',
                        loadChildren: () =>
                            import(/* webpackChunkName: "pk-pokerclub-create-club-module" */ './poker-club/create-club/create-club-routing').then(
                                (m) => m.createClubRoutes,
                            ),
                    },
                    {
                        path: 'join',
                        loadChildren: () =>
                            import(/* webpackChunkName: "pk-pokerclub-join-club-module" */ './poker-club/join-club/join-club-routing').then(
                                (m) => m.joinClubRoutes,
                            ),
                    },
                    {
                        path: 'clubinfo/:clubId',
                        loadChildren: () =>
                            import(/* webpackChunkName: "pk-pokerclub-club-info-module" */ './poker-club/club-info/club-info-routing').then(
                                (m) => m.clubInfoRoutes,
                            ),
                    },
                    {
                        path: 'createcashgame',
                        loadChildren: () =>
                            import(
                                /* webpackChunkName: "pk-pokerclub-create-cash-game-module" */
                                './poker-club/create-cash-game/create-cash-game-routing'
                            ).then((m) => m.createCashGameRoutes),
                    },
                    {
                        path: 'invitemember',
                        loadChildren: () =>
                            import(
                                /* webpackChunkName: "pk-pokerclub-invite-member-module" */
                                './poker-club/invite-member/invite-member-routing'
                            ).then((m) => m.inviteMemberRoutes),
                    },
                ],
            },
            {
                path: 'p',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerPublicPageLoaderRoutes),
            },

            {
                path: 's',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.portalPublicPageLoaderRoutes),
            },

            {
                path: 'pokerpromotionsHome',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerPromotionsHomeRoutes),
            },
            {
                path: 'download',
                loadChildren: () => import('@pokercore/module/poker-download').then((d) => d.pokerDownloadRoutes),
                data: {
                    publicPageRoot: 'MobilePoker-v1.0/download/',
                },
            },
            {
                path: 'clientdownload',
                component: PokerClientDownloadComponent,
                canActivate: [PokerDownloadDeactivateGuard],
            },
            {
                path: 'promotions',
                loadChildren: () =>
                    import(/* webpackChunkName: "pk-poker-promotions-module" */ '@pokercore/module/poker-promotions').then(
                        (p) => p.pokerPromotionsRoutes,
                    ),
            },
            {
                path: 'promotions/leaderboard',
                loadChildren: () => import('@pokercore/module/leaderboard').then((l) => l.leaderBoardRoutes),
            },
            {
                path: 'sidegames',
                loadChildren: () => import(/* webpackChunkName: "pk-sidegames" */ './sidegames/sidegames-routing').then((m) => m.sideGamesRoutes),
            },
            {
                path: 'tournamentsHome',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerTournamentsHomeRoutes),
            },

            {
                path: 'tournaments',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerTournamentsRoutes),
            },
            {
                path: 'power-series',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerPowerSeriesRoutes),
            },
            {
                path: 'loyalty',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerLoyaltyRoutes),
            },
            {
                path: 'millions-online',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerMillionsOnlineRoutes),
            },
            {
                path: 'partypoker-million',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerPartyPokerMillionRoutes),
            },
            {
                path: 'home-games',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerHomeGamesRoutes),
            },
            {
                path: 'cash-games',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerCashGamesRoutes),
            },
            {
                path: 'club-games',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerClubGamesRoutes),
            },
            {
                path: 'daily-legends',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerDailyLegendsRoutes),
            },
            {
                path: 'fast-forward',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerFastForwardRoutes),
            },
            {
                path: 'fastpoker',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerFastPokerRoutes),
            },
            {
                path: 'wpt-online-series',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerWptOnlineSeriesRoutes),
            },
            {
                path: 'wpt-world-online-championship',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerWptWorldOnlineChampionshipRoutes),
            },
            {
                path: 'powerfest',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerPowerFestRoutes),
            },
            {
                path: 'scheduled-tournaments',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerScheduledTournamentsRoutes),
            },
            {
                path: 'sit-and-go',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerSitAndGoRoutes),
            },
            {
                path: 'sit-and-go-jackpots',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerSitAndGoJackpotsRoutes),
            },
            {
                path: 'spins',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerSpinsRoutes),
            },
            {
                path: 'the-big-game',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerTheBigGameRoutes),
            },
            {
                path: 'tournament-dollars',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerTournamentDollarsRoutes),
            },
            {
                path: 'tricketts-room',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerTrickettsRoomRoutes),
            },
            {
                path: 'omaha-series',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerOmahaSeriesRoutes),
            },
            {
                path: 'short-deck',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerShortDeckRoutes),
            },
            {
                path: 'the-deck',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerTheDeckRoutes),
            },
            {
                path: 'ko-series',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerKoSeriesRoutes),
            },
            {
                path: 'monster-series',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerMonsterSeriesRoutes),
            },
            {
                path: 'run-it-twice',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerRunItTwiceRoutes),
            },
            {
                path: 'micro-series',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerMicroSeriesRoutes),
            },
            {
                path: 'refer-a-friend',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerReferAFriendRoutes),
            },
            {
                path: 'tornei-progressive-knockout',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerTorneiProgressiveKnockoutRoutes),
            },
            {
                path: 'hot-tables',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerHotTablesRoutes),
            },
            {
                path: 'my-game',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerMyGameRoutes),
            },
            {
                path: 'omaha-daily-legends',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerOmahaDailyLegendsRoutes),
            },
            {
                path: 'football-party',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerFootballPartyRoutes),
            },
            {
                path: 'world-cup-of-cards',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerWorldCupOfCardsRoutes),
            },
            {
                path: 'wpt-deepstacks-online',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerWPTDeepStacksOnlineRoutes),
            },
            {
                path: 'grand-prix-ko',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerGrandPrixKoRoutes),
            },
            {
                path: 'wpt-deepstacks',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerWptDeepstacksRoutes),
            },
            {
                path: 'irish-poker-masters-ko-online',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerIrishMastersKoOnlineRoutes),
            },
            {
                path: 'partypokerchampionship',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerPartypokerChampionshipRoutes),
            },
            {
                path: 'ko-week',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerKoWeekRoutes),
            },
            {
                path: 'la-notte-degli-assi',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerLaNotteDegliAssiRoutes),
            },
            {
                path: 'grand-prix-uk',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerGrandPrixUKRoutes),
            },
            {
                path: 'millions-online-knockout-edition',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerMillionsOnlineKnockoutEditionRoutes),
            },
            {
                path: 'p-dollars',
                loadChildren: () => import('@pokercore/module/poker-promotions').then((p) => p.pokerPDollarsRoutes),
            },
            {
                path: 'wpclogin',
                loadChildren: () =>
                    import(/* webpackChunkName: "pk-promotions-wpclogin" */ './wpc-login/wpc-login-routing').then((m) => m.wpcLoginRoutes),
            },
            {
                path: 'mobilebanner',
                loadChildren: () =>
                    import(/* webpackChunkName: "mobile-banner-routing-module" */ '@pokercore/module/mobile-banner').then(
                        (p) => p.mobileBannerRoutes,
                    ),
            },

            {
                path: 'bannerpreview',
                loadChildren: () =>
                    import(/* webpackChunkName: "banner-preview-routing-module" */ '@pokercore/module/mobile-banner').then(
                        (p) => p.bannerPreviewRoutes,
                    ),
            },
            {
                path: 'mobileplp',
                loadChildren: () =>
                    import(/* webpackChunkName: "web-client-plp-routing-module" */ '@pokercore/module/web-client-plp').then(
                        (p) => p.webClientPlpRoutes,
                    ),
            },
            {
                path: 'mobilepostlogin',
                loadChildren: () =>
                    import(/* webpackChunkName: "web-client-post-login-routing-module" */ '@pokercore/module/web-client-plp').then(
                        (p) => p.webClientPlpRoutes,
                    ),
            },
            {
                path: 'mobileplpwip',
                loadChildren: () =>
                    import(/* webpackChunkName: "web-client-post-login-wip-routing-module" */ '@pokercore/module/web-client-plp').then(
                        (p) => p.webClientPlpRoutes,
                    ),
            },
            {
                path: 'clientplp',
                loadChildren: () =>
                    import(/* webpackChunkName: "client-plp-routing-module" */ '@pokercore/module/client-postlogin').then(
                        (p) => p.clientPostLoginRoutes,
                    ),
            },
            {
                path: 'clientpostlogin',
                loadChildren: () =>
                    import(/* webpackChunkName: "client-postlogin-routing-module" */ '@pokercore/module/client-postlogin').then(
                        (p) => p.clientPostLoginRoutes,
                    ),
            },
            {
                path: 'clientplpwip',
                loadChildren: () =>
                    import(/* webpackChunkName: "client-postlogin-wip-routing-module" */ '@pokercore/module/client-postlogin').then(
                        (p) => p.clientPostLoginRoutes,
                    ),
            },
            {
                path: 'groupedbanner',
                loadChildren: () =>
                    import(/* webpackChunkName: "grouped-banner-routing-module" */ '@pokercore/module/poker-grouped-banner').then(
                        (p) => p.groupedBannersRoutes,
                    ),
            },
            {
                path: 'groupedbannerwip',
                loadChildren: () =>
                    import(/* webpackChunkName: "grouped-banner-wip-routing-module" */ '@pokercore/module/poker-grouped-banner').then(
                        (p) => p.groupedBannersRoutes,
                    ),
            },
            {
                path: 'how-to-play',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerHowToPlayPublicPageLoaderRoutes),
            },
            {
                path: 'alpha',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerAlphaPublicPageLoaderRoutes),
            },
            {
                path: 'live',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerLivePublicPageLoaderRoutes),
            },
            {
                path: 'evenements-poker',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerEvenementsPokerPublicPageLoaderRoutes),
            },
            {
                path: 'formats-de-jeu',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerFormatsDeJeuPublicPageLoaderRoutes),
            },
            {
                path: 'comission-et-equite-de-jeu',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerComissionEtEquiteDeJeuPublicPageLoaderRoutes),
            },
            {
                path: 'tournois',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerTournoisPublicPageLoaderRoutes),
            },
            {
                path: 'apprendre-le-poker',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerApprendreLePokerPublicPageLoaderRoutes),
            },
            {
                path: 'telecharger',
                loadChildren: () => import('@pokercore/module/poker-download').then((d) => d.pokerDownloadRoutes),
                data: {
                    publicPageRoot: 'MobilePoker-v1.0/telecharger/',
                },
            },
            {
                path: 'application-poker',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerApplicationPokerPublicPageLoaderRoutes),
            },
            {
                path: 'tornei',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerTorneiPublicPageLoaderRoutes),
            },
            {
                path: 'cashback',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerCashbackPublicPageLoaderRoutes),
            },
            {
                path: 'party-dollars',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.partyDollarsPublicPageLoaderRoutes),
            },
            {
                path: 'poker-chips',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerChipsPublicPageLoaderRoutes),
            },
            {
                path: 'apat-wcoap',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.apatWcoapPublicPageLoaderRoutes),
            },
            {
                path: 'ppc-czech',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.ppcCzechPublicPageLoaderRoutes),
            },
            {
                path: 'grand-prix-summer-edition',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.grandPrixSummerEditionPublicPageLoaderRoutes),
            },
            {
                path: 'irish-poker-masters',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.irishPokerMastersPublicPageLoaderRoutes),
            },
            {
                path: 'party-challenges',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerPartyChallengesPublicPageLoaderRoutes),
            },
            {
                path: 'grand-prix-autumn-edition',
                loadChildren: () =>
                    import('@pokercore/module/poker-public-page').then((p) => p.PokerGrandPrixAutumnEditionPublicPageLoaderRoutingModule),
            },
            {
                path: 'partydollars',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.nlPartyDollarsPublicPageLoaderRoutes),
            },
            {
                path: 'roadtotheworldcup',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.PokerRoadToTheWorldCupPublicPageLoaderRoutes),
            },
            {
                path: 'worldcupoverview',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.PokerWorldCupOverviewPublicPageLoaderRoutes),
            },
            {
                path: 'betontheworldcup',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.PokerBetOnTheWorldCupPublicPageLoaderRoutes),
            },
            {
                path: 'earnfreebets',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.PokerEarnFreeBetsPublicPageLoaderRoutes),
            },
            {
                path: 'footballfullhousewcedition',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.PokerFootballFullHouseWcEditionRoutes),
            },
            {
                path: 'soccerfullhousewcedition',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerSoccerFullHouseWcEditionRoutes),
            },
            {
                path: 'mystery-bounty',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerMysteryBountyRoutes),
            },
            {
                path: 'launch',
                children: [
                    {
                        path: '**',
                        component: PokerPublicPageLoaderComponent, //PokerLauncherPublicPageLoaderRoutes
                        data: {
                            publicPageRoot: 'MobilePoker-v1.0/launch/',
                        },
                        canActivate: [LauncherActivateGuard],
                    },
                ],
            },
            {
                path: 'conditions-generales-promotion-poker',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerConditionsGeneralesPromotionPokerPokerRoutes),
            },
            {
                path: 'fidelite',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerFideliteRoutes),
            },
            {
                path: 'tournois-au-poker',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerTournoisAuPokerPublicPageLoaderRoutes),
            },
            {
                path: 'team-pro-pmu-poker',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerTeamProPmuPokerPublicRoutes),
            },
            {
                path: 'tournois-et-evenements-poker-pmu',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerTournoisEtEvenementsPokerPmuRoutes),
            },
            {
                path: 'tournaments-test',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerTournamentRoutes),
            },
            {
                path: 'clientrtms',
                component: ClientRTMSComponent,
            },
            {
                path: 'mygamelogin',
                component: MyGameLoginComponent,
                canActivate: [MyGameLoginGuard],
            },
            {
                path: 'offers',
                loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerOffersPublicPageLoaderRoutes),
            },
            {
                path: 'play',
                loadChildren: () => import('@pokercore/module/poker-web-client').then((p) => p.pokerWebClientRoutes),
                canActivate: [PlayGuard],
            },
            {
                path: 'applauncher',
                children: [
                    {
                        path: '',
                        component: PokerApplicationLauncherComponent,
                        data: {
                            publicPageRoot: 'MobilePoker-v1.0/LandingPagePromos/index',
                        },
                    },
                    {
                        path: '**',
                        component: PokerApplicationLauncherComponent,
                        data: {
                            publicPageRoot: 'MobilePoker-v1.0/LandingPagePromos/',
                        },
                    },
                ],
            },
            {
                path: 'poker',
                children: [
                    ...POKER_LAZY_ROUTES,
                    {
                        path: 'p',
                        loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.pokerPublicPageLoaderRoutes),
                    },

                    {
                        path: '**',
                        loadChildren: () => import('@pokercore/module/poker-public-page').then((p) => p.PokerGenericPublicPageLoaderRoutingModule),
                    },
                ],
            },
            {
                path: 'bigwin',
                loadChildren: () => import('@pokercore/module').then((p) => p.BigWinRoutes),
            },
            {
                path: 'sessionlimit',
                loadChildren: () =>
                    import(/* webpackChunkName: "session-limit-routing-module" */ '@pokercore/module/session-limit').then(
                        (p) => p.sessionLimitRoutes,
                    ),
            },
        ],
    },
    {
        path: '**',
        loadChildren: () => import('@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
    },
];
