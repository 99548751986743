import { Route } from '@angular/router';

//import { DummyComponent } from '@pokercore/module';

export const POKER_LAZY_ROUTES: Route[] = [
    {
        path: 'handhistory',
        loadChildren: () =>
            import(/* webpackChunkName: "pk-hand-history-module" */ '@pokercore/module/hand-history').then((h) => h.HandHistoryRoutingModule),
    },
    {
        path: 'mytickets',
        loadChildren: () =>
            import(/* webpackChunkName: "pk-mytickets-module" */ '@pokercore/module/pk-mytickets').then((t) => t.pokerMyTicketsRoutes),
    },
    {
        path: 'download',
        loadChildren: () => import('@pokercore/module/poker-download').then((d) => d.pokerDownloadRoutes),
        data: {
            publicPageRoot: 'MobilePoker-v1.0/download/',
        },
    },
    {
        path: 'how-to-play',
        loadChildren: () =>
            import(/* webpackChunkName: "pk-how-to-play-public-page-loader-routing-module" */ '@pokercore/module/poker-public-page').then(
                (p) => p.pokerHowToPlayPublicPageLoaderRoutes,
            ),
    },
    {
        path: '',
        loadChildren: () => import(/* webpackChunkName: "pk-homepage-module" */ '@pokercore/module/homepage').then((p) => p.HomePageRoutingModule),
    },
    {
        path: 'promotions/leaderboard',
        loadChildren: () => import(/* webpackChunkName: "pk-leaderboard-module" */ '@pokercore/module/leaderboard').then((l) => l.leaderBoardRoutes),
    },
    {
        path: 'tournaments',
        loadChildren: () =>
            import(/* webpackChunkName: "pk-tournaments-module" */ '@pokercore/module/poker-promotions').then((p) => p.pokerTournamentsRoutes),
    },
    {
        path: 'promotions',
        loadChildren: () =>
            import(/* webpackChunkName: "pk-promotions-module" */ '@pokercore/module/poker-promotions').then((p) => p.pokerPromotionsRoutes),
    },
    {
        path: 'p',
        loadChildren: () =>
            import(/* webpackChunkName: "pk-public-page-loader-module" */ '@pokercore/module/poker-public-page').then(
                (p) => p.pokerPublicPageLoaderRoutes,
            ),
    },
];
